import { useNextStep, useSharedVariables } from 'hooks';
import { useCallback } from 'react';
import { isMobileDevice } from 'utils';

export const useBlogVideoUploadHook = () => {
	const { envHost } = useSharedVariables();
	const { sessionPayloadDetail } = useNextStep();

	const dataroomURL = useCallback(() => {
		if (envHost === 'stage') return 'https://api.stage.simplici.io/v1';
		if (envHost === 'preprod') return 'https://api.pp.simplici.io/v1';
		if (envHost === 'prod') return 'https://api.simplici.io/v1';
		if (envHost === 'beta') return 'https://api.beta.simplici.io/v1';
		return 'https://api.stage.simplici.io/v1';
	}, [envHost]);

	const UpdateURL = useCallback(
		async (signedId: string) => {
			const signedURLsucessPayload = JSON.stringify({
				files: [
					{
						id: signedId,
						status: 'SUCCESS',
					},
				],
			});
			try {
				const response = await fetch(
					`${dataroomURL()}/dataroom/${sessionPayloadDetail.sessionId}`,
					{
						method: 'PATCH',
						headers: {
							'Content-Type': 'application/json',
						},
						body: signedURLsucessPayload,
					}
				);

				if (!response.ok) {
					// eslint-disable-next-line no-console
					console.error('Failed to upload video');
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error('Error uploading video:', error);
			}
		},
		[dataroomURL, sessionPayloadDetail.sessionId]
	);

	// Function to get the signed URL
	const getSignedUrl = useCallback(
		async (sessionId: string) => {
			try {
				const response = await fetch(`${dataroomURL()}/dataroom`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						sessionId: sessionId,
						files: [
							{
								extension: isMobileDevice() ? 'video/mp4' : 'video/webm',
								uploadType: 'FACE',
							},
						],
					}),
				});

				if (!response.ok) {
					// eslint-disable-next-line no-console
					console.error('Failed to fetch signed URL');
				}

				const data = await response.json();
				UpdateURL(data?.data[0]?.id);
				return data?.data[0]?.signedUrl; // Adjust based on your backend response structure
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error('There was a problem with the fetch operation:', error);
			}
		},
		[UpdateURL, dataroomURL]
	);

	const uploadToSignedUrl = async (signedUrl: any, blob: any) => {
		try {
			const response = await fetch(signedUrl, {
				method: 'PUT',
				headers: {
					'Content-Type': blob.type,
				},
				body: blob,
			});

			if (!response.ok) {
				// eslint-disable-next-line no-console
				console.error('Failed to upload video');
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Error uploading video:', error);
		}
	};

	return {
		getSignedUrl,
		uploadToSignedUrl,
	};
};
