import { useState, useEffect } from 'react';
import { database } from './firebase'; // Import Firebase config
import { ref, onValue, off } from 'firebase/database';

/**
 * Custom hook to listen to real-time updates from Firebase Realtime Database.
 * @param {string} path - The database path to listen to.
 * @returns {T | null} - The latest data from the database.
 */
const useRealtimeDatabase = <T>(path: string): T | null => {
	const [data, setData] = useState<T | null>(null);

	useEffect(() => {
		if (!path) return; // Prevent listening if no path is provided

		const dataRef = ref(database, path);

		// Subscribe to real-time updates
		onValue(dataRef, snapshot => {
			if (snapshot.exists()) {
				setData(snapshot.val() as T);
			} else {
				setData(null);
			}
		});

		return () => {
			off(dataRef); // 🔹 Cleanup listener correctly
		};
	}, [path]);

	return data;
};

export default useRealtimeDatabase;
