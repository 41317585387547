import { atom } from 'recoil';

type AndroidVersionInfo = {
	version: string;
	isBelowAndroid10: boolean;
};

type FacialEnrollmentMethodStatus = {
	isMediaPipeAttempted: boolean;
	isAgoraAttempted: boolean;
};

export const AndroidVersionInfoState = atom<AndroidVersionInfo>({
	key: 'android-version-info-state-key',
	default: {
		version: '',
		isBelowAndroid10: false,
	},
});

export const IsFacialEnrollTriedState = atom<FacialEnrollmentMethodStatus>({
	key: 'facial-enrollment-method-state',
	default: {
		isMediaPipeAttempted: false,
		isAgoraAttempted: false,
	},
});
