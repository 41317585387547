import { atom } from 'recoil';
import { ICameraVideoTrack } from 'agora-rtc-sdk-ng';

export const selfieImageURL = atom<string>({
	key: 'selfieImage',
	default: '',
});

export const localVideoTrackState = atom<ICameraVideoTrack | null>({
	key: 'localVideoTrackState',
	default: null,
});

export const mediaRecorderState = atom<MediaRecorder | null>({
	key: 'mediaRecorderState',
	default: null,
});

export const recordedChunksState = atom<Blob[]>({
	key: 'recordedChunksState',
	default: [],
});

export const isPublishingState = atom<boolean>({
	key: 'isPublishingState',
	default: false,
});
