import { FC } from 'react';

import { BackIcon, BodyWrapper, LabelElement } from 'components';
import { EmailVerification } from './components';


interface IVerifyEmail {
	moveToEmailScreen: () => void;
	verificationStatus: string;
	hideBackButton?:boolean
}

export const VerifyEmail: FC<IVerifyEmail> = ({
	moveToEmailScreen,
	verificationStatus,
	hideBackButton = false
}) => {
	const handleGoBack = () => {
		moveToEmailScreen();
	};
	return (
		<BodyWrapper
			optionalClassName="VerifyEmail--container"
			label={
				hideBackButton ? <div className="OtpVerify--formHeader">
					<BackIcon handleGoBack={handleGoBack} />
					<LabelElement text="Back" />
				</div> : undefined
				
			}
			bodyElement={
				<EmailVerification verificationStatus={verificationStatus} />
			}
		/>
	);
};
