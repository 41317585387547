import { INode, IQrProcessState, IQrVerificationTypes } from './types';

import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { Loader } from '@storybook';

import { REACT_APP_KYC_DOMAIN as KYC_DOMAIN } from 'envs';
import { useNextStep, useNotification, useSharedVariables } from 'hooks';
import { useWebSocket } from 'hooks/web-socket';
import { isDesktopDevice } from 'utils/device';
import {
	QrDimensions,
	QrStatus,
	QrUser,
	QrVerification,
	SimpliciLogo2,
} from '../constant';
import { QrPageNavigateState, QrProcessState, ShowQrPageState } from './states';
import { QrPage } from '../qr-page';
import { SecureUrl } from 'hooks/web-socket/constant';
import { IsShowOwnerShipOptionKybMemberListState } from 'views/kyb/stores';

// Custom hook to manage KYC QR process and handle QR code actions
export const useQrPage = () => {
	// Recoil state management for visibility of the KYC QR page and navigation to other states
	const [showQrPage, setShowQr] = useRecoilState(ShowQrPageState);
	const [qrNavigate, setQrNavigate] = useRecoilState(QrPageNavigateState);
	const [qrProcess, setQrProcess] = useRecoilState(QrProcessState); // Get current KYC QR process details
	const resetQrNavigate = useResetRecoilState(QrPageNavigateState);
	const resetQrProcess = useResetRecoilState(QrProcessState); // Get current KYC QR process details
	const resetShowQrPage = useResetRecoilState(ShowQrPageState);
	const isPSBBussinesId = useRecoilValue(
		IsShowOwnerShipOptionKybMemberListState
	);

	// Retrieve shared variables like host URL and session ID for QR generation
	const { sessionId, getAuthQr, getKycQr, envHost } = useSharedVariables();

	// Retrieve session payload details from the next step (likely from a form or session state)
	const { sessionPayloadDetail, setSessionDetails } = useNextStep();

	// Get notification handler for error messages
	const { errorNotification } = useNotification();

	// WebSocket functions to manage the KYC connection process
	const { closeConnections, connectionCompleted, createConnection } =
		useWebSocket();

	// Memoize current action to prevent unnecessary re-computations during renders
	const { currentAction } = useMemo(
		() => sessionPayloadDetail ?? {}, // Default to an empty object if sessionPayloadDetail is undefined
		[sessionPayloadDetail]
	);

	/**
	 * Determine the type of connection (Primary or Secondary).
	 * Memoized for performance and recalculated when `getKycQr` changes.
	 */
	const getConnectionType = useMemo(
		() => (getAuthQr || getKycQr ? QrUser.Secondary : QrUser.Primary),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[getAuthQr]
	);

	// Effect to update navigation based on the current KYC QR process status
	useEffect(() => {
		// Check if there's a status in the KYC process
		if (qrProcess?.status) {
			const { status } = qrProcess ?? {};

			// If the current user is the Primary device
			if (getConnectionType === QrUser.Primary) {
				if (status === QrStatus.SECONDARY_CONNECTED) {
					setQrNavigate('qr-loader'); // Navigate to QR loader if secondary is connected
				} else if (status === QrStatus.KYC_COMPLETE) {
					setQrNavigate('qr-success-next'); // Navigate to success screen if KYC is complete
				} else if (status === QrStatus.SECONDARY_DISCONNECTED) {
					setQrNavigate('qr-page'); // Navigate to QR page if secondary is disconnected
				}
			} else {
				// If the current user is the Secondary device
				if (status === QrStatus.QR_SESSION_FULL) {
					setQrNavigate('in-process'); // Navigate to in-process screen if session is full
				} else if (status === QrStatus.TERMINATED) {
					setQrNavigate('terminated'); // Navigate to terminated screen if session is terminated
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [qrProcess?.status]);

	const handleReset = useCallback(() => {
		resetQrNavigate();
		resetQrProcess();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resetQrNavigate, resetQrProcess]);

	// Optimized callback to handle transitioning from QR to desktop view, closing connections as needed
	const continueWithDesktop = useCallback(() => {
		// Close WebSocket connections if connectionId exists
		if (qrProcess?.connectionId) {
			closeConnections(qrProcess.connectionId);
		}

		// Hide the KYC QR page and reset necessary states
		setShowQr(false);
		handleReset();
	}, [closeConnections, handleReset, qrProcess?.connectionId, setShowQr]);

	// Memoize the QR code properties to optimize re-renders
	const qrProps = useCallback(
		(verificationType: IQrVerificationTypes) => {
			// Determine the URL for the QR code based on the verification type
			let value;
			const qrDomain = isPSBBussinesId
				? 'https://secure.parkstatebank.com'
				: KYC_DOMAIN;

			switch (verificationType) {
				case QrVerification.Auth:
					value = `${qrDomain || SecureUrl[envHost as 'stage']}/${sessionId}?type=complex&session=complexSession&getAuthQr=true&connectionId=${qrProcess?.connectionId}`;
					break;
				case QrVerification.Kyc:
					value = `${qrDomain || SecureUrl[envHost as 'stage']}/${sessionId}?type=complex&session=complexSession&getKycQr=true&nodeId=${currentAction._id}&connectionId=${qrProcess?.connectionId}`;
					break;
				default:
					value = ''; // You can handle the default case if needed
					break;
			}

			return {
				// Constructed URL for the QR code
				value,

				// QR code size
				size: QrDimensions[verificationType]?.size ?? 200,

				// Set error correction level (H for high correction capability)
				level: 'H',

				// Configure image settings for the logo at the center of the QR code
				imageSettings: {
					src: SimpliciLogo2, // Logo to be displayed at the center
					height: QrDimensions[verificationType]?.logoSize ?? 50, // Height of the logo
					width: QrDimensions[verificationType]?.logoSize ?? 50, // Width of the logo
					excavate: true, // Ensure QR content doesn't overlap the logo
				},

				// This indicates that the QR code loading process is in progress until the role is assigned.
				qrLoading: !qrProcess?.role,
			};
		},
		[
			isPSBBussinesId,
			currentAction._id,
			qrProcess?.connectionId,
			qrProcess?.role,
			sessionId,
			envHost,
		] // Dependencies for recomputing qrProps
	);

	// Callback function to handle redirection based on the KYC QR process status
	const handleRedirectToNext = useCallback(() => {
		const { nodes } = qrProcess ?? {};

		// Check if node ID or stepId exists in the qrProcess
		const isValidNode = nodes?._id || nodes?.stepId;

		if (isValidNode) {
			// Set session details and hide QR page
			setSessionDetails(prev => ({ ...prev, nodes }));

			// Reset QR process and related states
			[resetQrProcess, resetQrNavigate, resetShowQrPage].forEach(fn => fn());
		} else {
			// Show error notification and navigate to reload page
			errorNotification('Something went wrong.');
			setQrNavigate('qr-success-reload');
		}
	}, [
		qrProcess,
		setSessionDetails,
		resetQrProcess,
		resetQrNavigate,
		resetShowQrPage,
		errorNotification,
		setQrNavigate,
	]);

	// Callback to handle incoming data from the WebSocket listeners and update KYC process state
	const handleEventListener = useCallback(
		(data: IQrProcessState) => {
			const { role, connectionId, message, status, nodes } = data ?? {};
			// Update the KYC QR process state based on the incoming data
			setQrProcess(prev => ({
				...prev,
				message,
				...(nodes && { nodes }),
				...(connectionId && { connectionId }),
				...(status && { status }),
				...(role && { role }),
			}));
		},
		[setQrProcess]
	);

	// Memoized callback to handle connection completion
	const handleConnectionComplete = useCallback(
		(resp: INode) => connectionCompleted(resp),
		[connectionCompleted]
	);

	// Memoized callback to handle connection creation
	const handleCreateConnection = useCallback(() => {
		// Proceed only if qrProcess.role is not set
		if (!qrProcess.role) {
			createConnection(handleEventListener);
		}
	}, [createConnection, handleEventListener, qrProcess.role]);

	// Memoized flag to determine if the current navigation state is mobile-specific
	const checksForMobile = useMemo(
		() =>
			// Check if the `qrNavigate` value matches any of the mobile-specific states
			['qr-success', 'in-process', 'terminated', 'qr-success-reload'].includes(
				qrNavigate
			),
		[qrNavigate]
	);

	const renderQrPages = useCallback(
		(type: IQrVerificationTypes) => {
			// Conditional rendering for QR verification flow

			// If either auth or KYC QR is needed
			if (getAuthQr || getKycQr) {
				// Show loader if qrProcess status is unavailable
				if (!qrProcess?.status) {
					return (
						<div className="loading-steps">
							<Loader /> {/* Loading spinner */}
						</div>
					);
				}

				// Render QR page for mobile users
				if (checksForMobile) {
					return <QrPage verificationType={type} />;
				}
				return null;
			}

			// Render QR page for desktop if conditions are met
			if (isDesktopDevice() && showQrPage && !getAuthQr && !getKycQr) {
				return <QrPage verificationType={type} />;
			}

			// Return null if no conditions are met
			return null;
		},
		// Dependencies for memoizing the function
		[checksForMobile, getAuthQr, getKycQr, qrProcess?.status, showQrPage]
	);

	const validateQrComplete = useCallback(
		(resp: INode, type: IQrVerificationTypes) => {
			let value = false;

			// Check if authentication QR is being processed
			if (getAuthQr) {
				if (type === QrVerification.Auth) {
					// If response stepId is KYC, hide QR and reset value
					if (resp.stepId === QrVerification.Kyc) {
						value = false;
						setShowQr(false);
					} else if (resp?.stepId && resp.stepId !== QrVerification.Kyc) {
						// Valid stepId, navigate to 'QR Success'
						setQrNavigate('qr-success');
						handleConnectionComplete(resp); // Complete the connection
						value = true;
					} else {
						// Invalid stepId, navigate to 'QR Success Reload'
						setQrNavigate('qr-success-reload');
						value = true;
					}
				} else {
					// Non-auth flow: navigate based on stepId presence
					if (resp?.stepId) {
						setQrNavigate('qr-success'); // Navigate to 'QR Success'
						handleConnectionComplete(resp); // Complete the connection
					} else {
						setQrNavigate('qr-success-reload'); // Navigate to 'QR Success Reload'
					}
					value = true;
				}
			} else if (getKycQr) {
				// If KYC QR is being processed, navigate based on stepId
				if (resp?.stepId) {
					setQrNavigate('qr-success'); // Navigate to 'QR Success'
					handleConnectionComplete(resp); // Complete the connection
				} else {
					setQrNavigate('qr-success-reload'); // Navigate to 'QR Success Reload'
				}
				value = true;
			} else {
				// If no QR flow is active, reset navigation and process
				resetQrNavigate();
				resetQrProcess();
				resetShowQrPage();
			}

			return value; // Return result after handling QR flow
		},
		[
			getAuthQr,
			getKycQr,
			setShowQr,
			setQrNavigate,
			handleConnectionComplete,
			resetQrNavigate,
			resetQrProcess,
			resetShowQrPage,
		]
	);

	// Return necessary functions and state to manage the KYC QR process
	return {
		continueWithDesktop, // Function to move to desktop view
		qrProps, // Properties for the QR code, e.g., size, type, etc.
		handleRedirectToNext, // Function to handle redirection after QR scanning
		handleEventListener, // Function to handle any events related to the QR process
		checksForMobile, // Boolean to check if the device is mobile
		renderQrPages, // Function to conditionally render the QR page based on certain conditions
		handleConnectionComplete, // Function to handle the completion of the QR connection
		handleCreateConnection, // Function to initiate the creation of a new connection
		validateQrComplete, // Function to validate and finalize the QR scanning process
	};
};
