import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { AndroidVersionInfoState } from 'states';

export const useAndroidVersion = (): (() => void) => {
	const setAndroidVersionInfo = useSetRecoilState(AndroidVersionInfoState);
	// For all android Version
	const updateAndroidVersion = useCallback(() => {
		const userAgent: string = navigator.userAgent || navigator.vendor || '';
		const match = userAgent.match(/Android\s([\d.]+)/i);

		if (match?.[1]) {
			// Extract the Android version
			const version: string = match[1];

			// Always set isBelowAndroid10 to true for Android devices
			setAndroidVersionInfo({
				version,
				isBelowAndroid10: true,
			});
		}
	}, [setAndroidVersionInfo]);

	return updateAndroidVersion;
};
