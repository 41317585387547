import { useCallback, useMemo } from 'react';
import { io } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';

import { REACT_APP_WEB_SOCKET as SERVER_URL } from 'envs';
import { useSharedVariables } from 'hooks/shared-variable';
import { QRListeners, QrEvents, QrUser, SocketUrl } from './constant';
import { INode, IQrProcessState } from 'views/qr-page';

/**
 * Custom React hook for managing WebSocket connections with Socket.IO.
 * It provides functionality to create connections, complete connection, and close sessions.
 */
export const useWebSocket = () => {
	// Shared variables that provide session-specific details
	const { getKycQr, getAuthQr, connectionId, envHost } = useSharedVariables();

	// eslint-disable-next-line no-console
	console.log({ SERVER_URL });

	/**
	 * Determine the type of connection (Primary or Secondary).
	 */
	const getConnectionType = useMemo(
		() => (getKycQr || getAuthQr ? QrUser.Secondary : QrUser.Primary),
		[getAuthQr, getKycQr]
	);

	/**
	 * Retrieve the connection ID.
	 * - Use the shared `connectionId` if available.
	 * - Generate a new UUID for Primary connections.
	 * Memoized to avoid unnecessary recalculations.
	 */
	const getConnectionId = useMemo(
		() => (getKycQr || getAuthQr ? connectionId : uuidv4()),
		[connectionId, getAuthQr, getKycQr]
	);

	/**
	 * Establish a WebSocket connection and set up listeners.
	 * @param {Function} handleListeners - Callback function to handle various WebSocket events.
	 * @returns {Function} Cleanup function to disconnect the WebSocket.
	 */
	const createConnection = useCallback(
		(handleListeners: (data: IQrProcessState) => void) => {
			// Initialize the Socket.IO client connection
			const socketInstance = io(SERVER_URL || SocketUrl[envHost as 'stage'], {
				transports: ['websocket'],
			});

			// Emit an event to join the room with session details
			socketInstance.emit(QrEvents.QR_KYC, {
				connectionId: getConnectionId,
				connectType: getConnectionType,
				type: QrEvents.JOIN_ROOM,
			});

			// Set up event listeners for the connection
			socketInstance.on(QRListeners.QR_ROLE_ASSIGNED, handleListeners);
			socketInstance.on(QRListeners.QR_STATUS, handleListeners);
			socketInstance.on(QRListeners.QR_ERROR, handleListeners);
			socketInstance.on(QRListeners.QR_DISCONNECTED, handleListeners);

			// Return a cleanup function to disconnect the socket on unmount
			return () => {
				socketInstance.disconnect();
			};
		},
		[envHost, getConnectionId, getConnectionType]
	);

	/**
	 * Notify the server that the process is complete.
	 * @param {Object} nodes - The response data to send to the server.
	 * @returns {Function} Cleanup function to disconnect the WebSocket.
	 */
	const connectionCompleted = useCallback(
		(nodes: INode) => {
			// Initialize the Socket.IO client connection
			const socketInstance = io(SERVER_URL || SocketUrl[envHost as 'stage'], {
				transports: ['websocket'],
			});

			// Emit an event to mark KYC completion
			socketInstance.emit(QrEvents.QR_KYC, {
				connectionId,
				nodes,
				type: QrEvents.KYC_COMPLETE,
			});

			// Return a cleanup function to disconnect the socket
			return () => {
				socketInstance.disconnect();
			};
		},
		[connectionId, envHost]
	);

	/**
	 * Close all connections for the specified session ID.
	 * @param {string} closeId - The session ID to disconnect.
	 */
	const closeConnections = useCallback(
		(closeId: string) => {
			// Initialize the Socket.IO client connection
			const socketInstance = io(SERVER_URL || SocketUrl[envHost as 'stage'], {
				transports: ['websocket'],
			});

			// Emit an event to close all connections for the session
			socketInstance.emit(QrEvents.QR_KYC, {
				connectionId: closeId,
				type: QrEvents.CONNECTION_CLOSED,
			});
		},
		[envHost]
	);

	// Return the functions to manage WebSocket connections
	return {
		createConnection,
		connectionCompleted,
		closeConnections,
	};
};
