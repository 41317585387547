import type { IRemoteVideoTrack, ICameraVideoTrack } from 'agora-rtc-sdk-ng';

interface StreamUser {
	uid: string | number;
	videoTrack: IRemoteVideoTrack | ICameraVideoTrack;
}

export class AgoraStreamManager {
	private static streams = new Map<string | number, StreamUser>();

	static setStream(
		uid: string | number,
		videoTrack: IRemoteVideoTrack | ICameraVideoTrack
	) {
		this.streams.set(String(uid), { uid, videoTrack });
	}
	static getStream(uid: string | number) {
		return this.streams.get(String(uid));
	}

	static removeStream(uid: string | number) {
		this.streams.delete(String(uid));
	}

	static playVideo(uid: string | number, element: HTMLElement) {
		const stream = this.streams.get(String(uid));
		if (stream?.videoTrack) {
			stream.videoTrack.play(element);
		}
	}

	static stopVideo(uid: string | number) {
		const stream = this.streams.get(String(uid));
		if (stream?.videoTrack) {
			stream.videoTrack.stop();
		}
	}
}

export const generateRandomString = (length: number = 6): string => {
	const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
	return `SIMPLCI__${Array.from({ length }, () => chars[Math.floor(Math.random() * chars.length)]).join('')}`;
};
