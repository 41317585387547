/* eslint-disable no-console */
import { useState, useCallback, useMemo } from 'react';
import AgoraRTC, {
	ICameraVideoTrack,
	CameraVideoTrackInitConfig,
	ILocalTrack,
} from 'agora-rtc-sdk-ng';

// State Management (Recoil)
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	activeScreenState,
	cameraPermissionDisplayMessage,
	isCameraPermission,
	isPublishingState,
	mediaRecorderState,
	recordedChunksState,
	selectedDeviceIdState,
} from '../states';
import {
	AccessTokenState,
	AndroidVersionInfoState,
	IsFacialEnrollTriedState,
} from 'states';

// Custom Hooks
import { useNextStep } from 'hooks';
import { useBlogVideoUploadHook } from './use-blob-video-hook';

// Utilities & Environment Variables
import {
	AgoraStreamManager,
	// generateRandomString,
	isMobileDevice,
} from 'utils';
import {
	REACT_APP_AGORA_ENROLL_API_HOST,
	REACT_APP_AGORA_ENROLL_API_ID,
} from 'envs';
import { CameraPermisionsErrors } from '../constant';

const agoraClient = AgoraRTC.createClient({ mode: 'rtc', codec: 'h264' });

export const useAgoraVideoStream = () => {
	// Constants
	// const [CHANNEL_NAME] = useState(() => generateRandomString(6));
	// This comment code is for Testing purpose only
	// console.log("CHANNEL_NAME",CHANNEL_NAME)
	const CHANNEL_NAME = 'SimpliciEnroll';
	// const isConnected = useIsConnected();
	// console.log("isConnected",isConnected)
	// const CHANNEL_NAME = 'test';
	// Recoil State
	const { code } = useRecoilValue(AccessTokenState);
	const cameraId = useRecoilValue(selectedDeviceIdState);
	const activeScreen = useRecoilValue(activeScreenState);
	const [isPublishing, setIsPublishing] = useRecoilState(isPublishingState);
	const [mediaRecorder, setMediaRecorder] = useRecoilState(mediaRecorderState);
	const [recordedChunks, setRecordedChunks] =
		useRecoilState(recordedChunksState);
	const [isPublishingStart, setIsPublishingStart] = useState(false);
	const setActiveScreen = useSetRecoilState(activeScreenState);
	const setAndroidVersionInfo = useSetRecoilState(AndroidVersionInfoState);
	const [enrollmentStatus, setEnrollmentStatus] = useRecoilState(
		IsFacialEnrollTriedState
	);
	const setCameraPermission = useSetRecoilState<boolean | null>(
		isCameraPermission
	);
	const setCameraPermissionMessage = useSetRecoilState<string>(
		cameraPermissionDisplayMessage
	);

	// Local State
	const [error, setError] = useState<string | null>(null);
	const [joined, setJoined] = useState(false);
	const [, setIsRecording] = useState(false);

	// Custom Hooks
	const { getSignedUrl, uploadToSignedUrl } = useBlogVideoUploadHook();
	const { sessionPayloadDetail } = useNextStep();

	const localVideoTrack = useMemo(
		() => AgoraStreamManager.getStream(cameraId),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[cameraId, isPublishingStart]
	);

	const initAgora = useCallback(
		async (token: string) => {
			try {
				await agoraClient.join(
					REACT_APP_AGORA_ENROLL_API_ID,
					CHANNEL_NAME,
					token || null,
					null
				);
				setJoined(true);
				const videoTrackOptions: CameraVideoTrackInitConfig = {
					facingMode: 'user',
					optimizationMode: 'motion',
					cameraId,
				};
				// const videoTrackOptions: CameraVideoTrackInitConfig = {
				// 	encoderConfig: {
				// 		width: { ideal: 1920, max: 1920, min: 640 }, // Allow lower resolutions if needed
				// 		height: { ideal: 1080, max: 1080, min: 360 },
				// 		frameRate: { ideal: 60, max: 60, min: 15 }, // Adjust FPS dynamically
				// 		bitrateMax: 5000, // Reduce max bitrate for better stability
				// 		bitrateMin: 1000,
				// 	},
				// 	cameraId,
				// };
				const videoTrack =
					await AgoraRTC.createCameraVideoTrack(videoTrackOptions);
				AgoraStreamManager.setStream(cameraId, videoTrack!);
				return videoTrack;
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error('Agora initialization error:', error);
				return null;
			}
		},
		[CHANNEL_NAME, cameraId]
	);

	const handleDataAvailable = (event: BlobEvent) => {
		if (event.data.size > 0) {
			setRecordedChunks(prev => [...prev, event.data]);
		} else {
			// eslint-disable-next-line no-console
			console.log('Empty chunk received');
		}
	};

	const startRecording = useCallback(
		(stream: MediaStream) => {
			console.log('startRecording---called', stream);
			let options: MediaRecorderOptions | undefined;
			if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
				options = { mimeType: 'video/webm; codecs=vp9' };
			} else if (MediaRecorder.isTypeSupported('video/webm')) {
				options = { mimeType: 'video/webm' };
			} else if (MediaRecorder.isTypeSupported('video/mp4')) {
				options = { mimeType: 'video/mp4', videoBitsPerSecond: 100000 };
			} else {
				// eslint-disable-next-line no-console
				console.log('No suitable mimeType found for this device');
				return;
			}

			try {
				const recorder = new MediaRecorder(stream, options);

				recorder.ondataavailable = event => {
					if (event.data && event.data.size > 0) {
						if (activeScreen === 'facial-enroll') {
							handleDataAvailable(event);
						}
					}
				};

				recorder.start(1000); // Emit data every second
				setIsRecording(true);
				setMediaRecorder(recorder);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log('Error starting MediaRecorder:', error);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[activeScreen]
	);

	const publish = useCallback(
		async (vidTrack?: ICameraVideoTrack) => {
			console.log('videoTrack', vidTrack);
			const track = vidTrack;
			const trackBlob = vidTrack?.getMediaStreamTrack();
			const stream = new MediaStream(trackBlob ? [trackBlob] : []);
			if (!track) {
				setError('Video track is not available.');
				return;
			}
			try {
				await agoraClient?.publish([track]);
				console.log('Video Publishing Start !.');
				setIsPublishingStart(true);
				if (recordedChunks.length === 0) {
					startRecording(stream);
				}
				setIsPublishing(true);
			} catch (err) {
				// eslint-disable-next-line no-console
				console.log('Error publishing webcam track:', err);
				setError((err as Error).message);
			}
		},
		[recordedChunks.length, setIsPublishing, startRecording]
	);

	const stopRecording = useCallback(() => {
		if (mediaRecorder) {
			mediaRecorder.stop();
			mediaRecorder.onstop = async () => {
				const recordingMimeType = isMobileDevice() ? 'video/mp4' : 'video/webm';
				const blob = new Blob(recordedChunks, {
					type: recordingMimeType,
				});

				const signedUrl = await getSignedUrl(sessionPayloadDetail.sessionId);
				await uploadToSignedUrl(signedUrl, blob);
				// eslint-disable-next-line no-console
				console.log('Video uploaded successfully');
			};
			setIsRecording(false);
		}
	}, [
		getSignedUrl,
		mediaRecorder,
		recordedChunks,
		sessionPayloadDetail.sessionId,
		uploadToSignedUrl,
	]);

	const stopPublishing = useCallback(async () => {
		stopRecording();
		if (isPublishing && localVideoTrack?.videoTrack) {
			console.log('Video Published Stoped ', isPublishing);
			try {
				await agoraClient?.unpublish(
					localVideoTrack?.videoTrack as ILocalTrack
				);
				setIsPublishing(false);
			} catch (err) {
				setError((err as Error).message);
			}
		}
	}, [isPublishing, localVideoTrack, setIsPublishing, stopRecording]);

	const stopAgora = useCallback(async () => {
		return new Promise(resolve => {
			const url = `${REACT_APP_AGORA_ENROLL_API_HOST}/stop`;
			const params = new URLSearchParams({
				session_id: code ?? '',
			});

			fetch(`${url}?${params.toString()}`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({}),
			})
				.then(response => response.json())
				.then(data => resolve(data))
				.catch(error => {
					// eslint-disable-next-line no-console
					console.error('Failed to stop Agora:', error);
					resolve(null); // Ensure promise resolves even if there is an error
				});
		});
	}, [code]);

	const showErrorScrenn = useCallback(() => {
		setActiveScreen('liveness-information');
		setCameraPermission(false);
		setCameraPermissionMessage(CameraPermisionsErrors.WASM_ERROR);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getTokenAndInitAgora = useCallback(async () => {
		try {
			const url = `${REACT_APP_AGORA_ENROLL_API_HOST}/token/${CHANNEL_NAME}`;
			const response = await fetch(`${url}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			});
			const data = await response.json();
			if (data?.token) {
				const resp = await initAgora(data.token);
				if (resp) {
					return { token: data?.token, videoTrack: resp };
				}
			} else {
				if (!enrollmentStatus.isMediaPipeAttempted) {
					setEnrollmentStatus(prevState => ({
						...prevState,
						isAgoraAttempted: true,
					}));
					setAndroidVersionInfo({ version: '', isBelowAndroid10: false });
					setActiveScreen('facial-enroll');
				} else {
					showErrorScrenn();
				}
			}
			return { token: '', videoTrack: null };
		} catch (error) {
			console.log('Error fetching token:', error);
			if (!enrollmentStatus.isMediaPipeAttempted) {
				setEnrollmentStatus(prevState => ({
					...prevState,
					isAgoraAttempted: true,
				}));
				setAndroidVersionInfo({ version: '', isBelowAndroid10: false });
				setActiveScreen('facial-enroll');
			} else {
				showErrorScrenn();
			}
			return { token: '', videoTrack: null };
		}
	}, [
		CHANNEL_NAME,
		enrollmentStatus.isMediaPipeAttempted,
		initAgora,
		setActiveScreen,
		setAndroidVersionInfo,
		setEnrollmentStatus,
		showErrorScrenn,
	]);

	const cleanupAgora = useCallback(() => {
		agoraClient?.leave();
	}, []);

	const startProcessingVideo = useCallback(async () => {
		try {
			console.log('startProcessingVideo');
			const url = `${REACT_APP_AGORA_ENROLL_API_HOST}/start`;
			const params = new URLSearchParams({
				sessionId: code ?? '',
				channelName: CHANNEL_NAME,
			});

			await fetch(`${url}?${params.toString()}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'ngrok-skip-browser-warning': 'true',
				},
			});
		} catch (error) {
			console.error('Error fetching token:', error);
		}
	}, [CHANNEL_NAME, code]);

	return {
		agoraClient,
		joined,
		error,
		isPublishingStart,
		isPublishing,
		stopPublishing,
		publish,
		initAgora,
		stopAgora,
		getTokenAndInitAgora,
		cleanupAgora,
		startProcessingVideo,
	};
};
