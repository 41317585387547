// Enumerates WebSocket event types used in QR-based interactions.
export enum QrEvents {
	JOIN_ROOM = 'JOINROOM', // Event to request joining a specific room or session.
	KYC_COMPLETE = 'KYCCOMPLETE', // Event emitted when a process is successfully completed.
	CONNECTION_CLOSED = 'CONNECTIONCLOSED', // Event to notify all participants that the session has been terminated.
	QR_KYC = 'kyc', // General-purpose event for all QR-related actions or messages.
}

// Enumerates server-side WebSocket listeners for handling responses during QR-based interactions.
export enum QRListeners {
	QR_ROLE_ASSIGNED = 'qr-roleAssigned', // Triggered when the server assigns a role (e.g., Primary/Secondary) to a user.
	QR_STATUS = 'qr-status', // Triggered to send real-time updates about the process status or current step.
	QR_ERROR = 'qr-error', // Triggered to notify the client about any errors or issues during the session.
	QR_DISCONNECTED = 'disconnected', // Triggered when a user or device is disconnected from the session.
}

// Enumerates user roles in QR-based interactions, defining their responsibilities and access levels.
export enum QrUser {
	Primary = 'primary', // Represents the main user initiating or managing the session or process.
	Secondary = 'secondary', // Represents a supporting user joining the session, typically for collaboration or validation.
}

export enum SocketUrl {
	stage = 'https://socket.stage.satschel.com',
	preprod = 'https://socket.pp.satschel.com',
	beta = 'https://socket.beta.satschel.com',
	prod = 'https://socket.satschel.com',
}

export enum SecureUrl {
	stage = 'https://secure.stage.satschel.com',
	preprod = 'https://secure.pp.satschel.com',
	beta = 'https://secure.beta.simplici.io',
	prod = 'https://secure.simplici.io',
}
