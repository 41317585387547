export const  deepMerge = (defaultConfig:any, overrideConfig:any) => {
    if (typeof defaultConfig !== 'object' || defaultConfig === null) {
        return overrideConfig !== undefined ? overrideConfig : defaultConfig;
    }

    if (Array.isArray(defaultConfig)) {
        return Array.isArray(overrideConfig) ? overrideConfig : defaultConfig;
    }

    const mergedConfig = { ...defaultConfig };

    for (const key in overrideConfig) {
        if (overrideConfig[key] && typeof overrideConfig[key] === 'object' && !Array.isArray(overrideConfig[key])) {
            mergedConfig[key] = deepMerge(defaultConfig[key], overrideConfig[key]);
        } else {
            mergedConfig[key] = overrideConfig[key];
        }
    }

    return mergedConfig;
}